ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: #0fdd4d;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #c5fad7;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
